<template>
  <div
    class="rule-container"
    :style="hideHeader ? 'padding-top: 0' : ''"
  >
    <van-nav-bar
      v-if="!hideHeader"
      :title="protocolTitle"
      left-arrow
      fixed
      @click-left="$router.back()"
    />

    <div
      v-if="useStatic"
      class="px-15 mt-1 pb-5 text-dark text-14 static-container"
    >
      <slot />
    </div>
    <div v-else>
      <template v-if="!loading">
        <div
          :class="realClassName"
          v-html="htmlProtocol"
        />
      </template>
      <div
        v-if="loading"
        class="bg-white rounded p-8 text-center"
      >
        <div class="text-orange-500">
          <i class="el-icon-loading text-3xl" />
        </div>
      </div>
    </div>

    <BottomActionBar
      v-if="needRead"
      class="box-shadow"
    >
      <div class="btn-wrap">
        <van-button
          round
          :disabled="!pass"
          :color="!pass ? '#D1D6E3' : '#FFD811'"
          style="width: 100%"
          @click="handleBottomAction"
        >
          <span
            :style="{
              fontSize: '14px',
              color: '#333',
            }"
          >{{ textReadBtn }}</span>
        </van-button>
      </div>
    </BottomActionBar>
  </div>
</template>

<script>
import { getProtocolById } from '@api'
import BottomActionBar from '@components/BottomActionBar.vue'
import { debounce } from 'lodash'

export default {
  components: {
    BottomActionBar
  },
  props: {
    type: [String, Number],
    title: String,
    className: String,
    useStatic: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    staticType: [String]
  },
  data () {
    return {
      protocolTitle: this.title || '',
      htmlProtocol: '',
      status: 0,
      loading: false,
      mustReady: false,
      duration: -1,
      seconds: -1,
      bottomReached: false,
      needRead: false
    }
  },
  computed: {
    realClassName () {
      return this.className || 'px-15 mt-1 pb-4 text-dark text-14'
    },
    textReadBtn () {
      return this.pass
        ? '同意并继续'
        : `请上滑阅读完全部协议${this.seconds ? ` ${this.seconds}s` : ''}`
    },
    pass () {
      return !this.mustReady || (this.seconds === 0 && this.bottomReached)
    }
  },
  created () {
    const { mustReady, duration, needRead } = this.$route.query || {}
    this.mustReady = false
    this.duration = Number(duration)
    this.seconds = Number(duration)
    this.needRead = needRead
  },
  mounted () {
    if (this.type && !this.useStatic) {
      this.loading = true
      getProtocolById(this.type).then(({ data }) => {
        const protocol = data.protocol || {}
        this.protocolTitle = protocol.title || ''
        this.htmlProtocol = protocol.content.replace(/white-space:\s*pre;/g, '') || ''
        this.loading = false
      })
    }
    if (this.mustReady) {
      this.startCountDown()
    }

    const callBackScroll = debounce(() => {
      if (this.bottomReached) return
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      if (scrollTop + windowHeight + 40 >= scrollHeight) {
        this.bottomReached = true
      }
    }, 100)
    window.addEventListener('scroll', callBackScroll)
    window.scrollTo(0, 1)

    const hasScrollbar =
      document.body.scrollHeight >
      (window.innerHeight || document.documentElement.clientHeight)
    if (!hasScrollbar) {
      this.bottomReached = true
    }
  },
  beforeUnmount () {
    let type = this.type

    if (this.staticType) {
      type = this.staticType
    }

    if (this.pass) {
      localStorage.setItem(`${type}.readed`, true)
    }
  },
  methods: {
    startCountDown () {
      this._timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds = this.seconds - 1
        } else {
          clearInterval(this._timer)
        }
      }, 1000)
    },
    handleBottomAction () {
      if (this.pass) {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  @apply font-medium text-black;
}

.static-container {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
.btn-wrap {
  padding-top: 4px;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
