import http from '@/utils/http'

export const getUniversities = (params: any) => http.get('universities', { params })

export const getCoveredUniversities = () => http.get('covered-universities')

export const getMajors = (params: any) => http.get('majors', { params })

// OSS
export const getOssConfig = () => http.get('get-oss-message')

// Upload Policy
export const getUploadPolicy = () => http.get('upload/policy')
// 新版本OSS配置
/**
 * @param params.dir 上传的目录名称
 * @param params.product_line  订单产品线类型:1HD-class,2HD-1v1,3HD-Career,4HD-Offer。如果没有传空 或不传。
 * @returns
 */
export const getUploadConfig = (params: any) => http.get('oss/policy', { params })
// Regions
export const getRegions = (options: any) => {
  let params = {}

  if (options) {
    params = Object.assign(params, options)
  }

  return http.get('get-region', params)
}
// Finger Print
export const postFinger = (params: any) => http.post('finger/save', params)

// 获取用户课程、章节、视频
export const getUserClassList = (params: any) => http.get('v3/user/class-time-video-list', { params })
// Skills
export const getSkills = (params: any) => http.get('common/skill', { params })

// Major Types
export const getMajorTypes = () => http.get('common/major-types')

// Zoom
// 获取 Zoom 直播基本信息
export const getZoomLiveInfo = (id: number) => http.get(`/zoom/live-base-info?lesson_id=${id}`)

// HiLink
// 获取 HiLink 直播基本信息
export const getHiLinkLiveInfo = (id: number) => http.get(`/hi-link/live-base-info?lesson_id=${id}`)

// HiLink 1v1 直播基本信息
export const getHiLinkLiveInfo1v1 = (id: number | string) => {
  return http.get(`/product/hi-link/live-base-info/${id}`)
}

// 完课率打点
export const postStudentClassTrace = (params: any) => http.post('student-class-trace', params)

// Zoom 跳到客户端埋点
export const postToZoomApp = (params: any) => http.post('zoom/create-record', params)

// 用户封禁提示
export const getFingerTip = () => http.get('user/finger/tip')

// 协议配置相关
/**
 * @param {string} key - protocol | refund_protocol | 1v1_refund_protocol | copyright_protocol | balance_protocol | privacy_protocol
 */
export const getProtocol = (key: string = 'protocol') => {
  return http.get('/protocol', {
    params: {
      key
    }
  })
}

// 获取协议列表
export const getProtocolList = () => http.get('protocol/list')

// 根据 ID 获取协议信息
export const getProtocolById = (id: number) => http.get('protocol/detail', { params: { id } })

// 根据 ID 获取 Tutor 协议信息
export const getTutorProtocolById = (id: number, params: any) => http.get('tutor-protocol/detail', { params: { id, ...params } })

// 获取验证码
export const getCaptcha = () => http.get('sms/code')

// 获取 Paypal Token
export const generatePaypalToken = (params: any) => http.post('paypal/generate-token', params)

// 获取 RSA 公钥
export const getRSAPubKey = () => http.get('rsa/getPub')

// 获取 LV3 以上的用户小助手二维码
export const getLv3Qrcode = () => http.get('show-v3-coupon')

export const postUserTrack = (params: any) => http.post('user-track', {
  ...params,
  source: 'wap'
})
